import './css/whychooseus.css';

import ChooseUsImgBack from './images/whychooseus/lg.webp';
import WhyChooseUsImg from './images/whychooseus/md.webp';


function WhyChooseUs(){
    return(
        <>
            <section id="why-us" className='why-choose-us'>
                <img src={ChooseUsImgBack} alt="why choose eleven section back image" />
                <div className='overlay'></div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div  className='number'>
                            <i class="las la-smile"></i>
                                <h1>
                                    95 %
                                </h1>
                                <h2> 
                                نسبة رضا العملاء
                                </h2>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='number'>
                            <i class="las la-clipboard-check"></i>
                                <h1>
                                300 +
                                </h1>
                                <h2> 
                                مشروع ناجح
                                </h2>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div  className='number'>
                            <i class="las la-chart-line"></i>
                                <h1>
                                    60 %
                                </h1>
                                <h2> 
                                نمو العلامات التجارية لعملائنا 
                                </h2>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div  className='number'>
                            <i class="las la-chart-line"></i>
                                <h1>
                                100  +
                                </h1>
                                <h2> 
                                فريق عمل خبير
                                </h2>
                            </div>
                        </div>


                        <div className='col-12'>
                            <div className='why-choose-us-header'>
                                <div>
                                    <h2 data-aos-delay="100" data-aos="fade-in"> لماذا تختار "إليفن لخدمات الأعمال"? <span> </span> </h2>
                                    <h1 data-aos-delay="200" data-aos="fade-in">
                                    في "إليفن لخدمات الأعمال"، نفخر بتقديم خدماتنا المتميزة التي تضعنا في المقدمة
                                    </h1>
                                </div>

                                <p data-aos-delay="300" data-aos="fade-in">
                                    اختيارك لـ "إليفن لخدمات الأعمال" يعني الشراكة مع وكالة تضع احتياجات عملائها في أولوياتها، وتستخدم أحدث التقنيات وأفضل الاستراتيجيات لضمان تحقيق الأهداف بكفاءة وفعالية. نحن هنا لنساعدك على التألق والتميز في عالم الأعمال الرقمي.

                                </p>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div  className='choose-benefit-text'>
                                <div data-aos-delay="100" data-aos="fade-in">
                                <i class="las la-user-cog"></i>
                                    <h3>
                                    الخبرة الواسعة والاحترافية
                                    </h3>

                                    <a href="tel:+966509288992" aria-label='call us'>
                                        <i class="las la-phone-volume"></i>
                                    </a>
                                </div>

                                <div data-aos-delay="200" data-aos="fade-in">
                                    <i class="las la-project-diagram"></i>
                                    <h3>
                                        حلول متكاملة ومبتكرة
                                    </h3>

                                    <a href="tel:+966509288992" aria-label='call us'>
                                        <i class="las la-phone-volume"></i>
                                    </a>
                                </div>


                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div data-aos-delay="300" data-aos="fade-in" className='choose-benefit-img'>
                                <img src={WhyChooseUsImg} alt="why choose eleven" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default WhyChooseUs;