import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import { useState, useRef } from "react";



import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import Navbar from './components/Navbar';
import Landing from './components/Landing';
import MainService from './components/MainService';
import About from './components/About';
import Services from './components/Services';
import WhyChooseUs from './components/WhyChooseUs';
import Rates from './components/Rates';
import Benefits from './components/Benefits';
import LatestWork from './components/LatestWork';
import Footer from './components/Footer';

import ScrollToTopButton from './components/ScrollToTopButton';


import NavbarEN from './components/en/Navbar';
import LandingEN from './components/en/Landing';
import MainServiceEN from './components/en/MainService';
import AboutEN from './components/en/About';
import ServicesEN from './components/en/Services';
import WhyChooseUsEN from './components/en/WhyChooseUs';
import RatesEN from './components/en/Rates';
import BenefitsEN from './components/en/Benefits';
import LatestWorkEN from './components/en/LatestWork';
import FooterEN from './components/en/Footer';

import ScrollToTopButtonEN from './components/en/ScrollToTopButton';
import WhatsappEN from './components/en/Whatsapp';


import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Whatsapp from './components/Whatsapp';


function App() {




    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, [])
    
return (
    <div className="App">
        



            <Routes>
                

            <Route path="/" element={
                    <>
                        <Whatsapp />
                        <ScrollToTopButton />
                        <Navbar />
                        <Landing />
                        <MainService />
                        <About />
                        <Services />
                        <WhyChooseUs />
                        <Rates />
                        <Benefits />
                        <LatestWork />
                        <Footer />


                    </>
                } />


                <Route path="/en" element={
                    <>
                        <WhatsappEN />
                        <ScrollToTopButtonEN  />
                        <NavbarEN  />
                        <LandingEN  />
                        <MainServiceEN  />
                        <AboutEN  />
                        <ServicesEN  />
                        <WhyChooseUsEN  />
                        <RatesEN  />
                        <BenefitsEN  />
                        <LatestWorkEN  />
                        <FooterEN  />


                    </>
                } />

        </Routes>


        
    </div>
);
}

export default App;
