import './css/mainservice.css';
import MainService1 from './images/mainservices/1.webp';
import MainService2 from './images/mainservices/2.webp';
import MainService3 from './images/mainservices/3.webp';


function MainService(){
    return(
        <>
            <section className='main-service'>
                <div className='container'>
                    <div className='row'>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div data-aos-delay="100" data-aos="fade-in" className='service'>
                                <span> 
                                <i class="las la-print"></i>
                                </span>

                                <img src={MainService1} alt="main service image"/>

                                <div className='text'>
                                    <h1>
                                        خدمات الطباعة 
                                    </h1>

                                    <p>
                                        <strong >طباعة متنوعة </strong>  :
                                        ( استكر - تيشيرت - بلوفر - بولو -لاينارد - اكواب - اكياس قماشية - .... الخ )
                                        <br></br>
                                        <strong>  انواع الطباعة </strong>:
                                        DTF - سبلميشن - سلك سكرين - فينيل - uv dtf 
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div data-aos-delay="200" data-aos="fade-in" className='service'>
                                <span> 
                                    <i class="las la-store-alt"></i>
                                </span>

                                <img src={MainService2} alt="main service image"/>

                                <div className='text'>
                                    <h1>
                                        التسويق الإلكتروني
                                    </h1>

                                    <p>
                                    حملات دعائية عبر منصات متعددة لزيادة الوعي بالعلامة التجارية    
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div data-aos-delay="300" data-aos="fade-in" className='service'>
                                <span> 
                                <i class="las la-credit-card"></i>
                                </span>

                                <img src={MainService3} alt="main service image"/>

                                <div className='text'>
                                    <h1>
                                    المتاجر الإلكترونية
                                    </h1>

                                    <p>
                                        إنشاء متاجر إلكترونية متكاملة من الصفر مع تصوير المنتجات
                                    </p>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </section>
        </>
    )
}

export default MainService;