import './css/about.css';

import AboutImg1 from './images/about/lg_0.webp';
import AboutImg2 from './images/about/sm_1.webp';

import BenImg1 from  './images/about/ben1_0.webp';
import BenImg2 from  './images/about/ben2_1.webp';

function About(){
    return(
        <>
            <section id="about-us" className='about'>
                <div className='container'>
                    <div className='row'>

                        <div className='col-lg6 col-md-6 col-sm-12 col-12'>
                            <div className='about-text'>
                                <h2 data-aos-delay="100" data-aos="fade-in">About the Company</h2>

                                <h1 data-aos-delay="200" data-aos="fade-in">
                                    Our Leadership in the World of E-Business and Marketing
                                </h1>

                                <p data-aos-delay="300" data-aos="fade-in">
                                "Eleven Business Services," a pioneering Saudi agency specializing in offering a comprehensive range of electronic and marketing services, including designing and implementing e-marketing solutions, content management, advertising and publicity, visual identity design, product photography, in addition to developing and managing e-stores. At "Eleven," we strive to provide distinguished services that ensure our clients shine and succeed in the digital business world.
                                </p>

                                <div className='benefits'>
                                    <div data-aos-delay="400" data-aos="fade-in">
                                        <span>
                                            <i class="las la-award"></i>
                                        </span>
                                        <h3>Professionalism and Quality</h3>
                                        <p>
                                        We offer our clients meticulous and innovative services that reflect our extensive experience and professionalism in every project we undertake.
                                        </p>

                                        <img src={BenImg1} alt="benefit image" />
                                    </div>

                                    <div data-aos-delay="500" data-aos="fade-in">
                                        <span>
                                            <i class="las la-puzzle-piece"></i>
                                        </span>
                                        <h3>Comprehensiveness and Integration</h3>
                                        <p> By offering a wide range of services under one roof, we provide our clients with integrated solutions that meet all their digital and marketing needs, helping them achieve their goals efficiently and effectively.</p>

                                        <img src={BenImg2} alt="benefit image" />
                                    </div>

                                </div>

                                <ul>
                                    <li data-aos-delay="100" data-aos="fade-in">
                                        <i class="las la-check"></i>
                                        Commitment to Quality
                                    </li>

                                    <li data-aos-delay="200" data-aos="fade-in">
                                        <i class="las la-check"></i>
                                        Dedication in Implementation
                                    </li>

                                    <li data-aos-delay="300" data-aos="fade-in">
                                        <i class="las la-check"></i>
                                        Meeting Market Needs
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className='col-lg6 col-md-6 col-sm-12 col-12'>
                            <div className='about-image'>
                                <div className='spans'>
                                    <span data-aos-delay="100" data-aos="fade-in" className='long'></span>
                                    <span data-aos-delay="200" data-aos="fade-in" className='short'></span>
                                    <span data-aos-delay="300" data-aos="fade-in" className='short'></span>
                                </div>

                                <img data-aos-delay="100" data-aos="fade-in" className='lg-img' src={AboutImg1} alt="eleven about image"/>

                                <img data-aos-delay="200" data-aos="fade-in" className='sm-img' src={AboutImg2} alt="eleven about image"/>

                                <div data-aos-delay="300" data-aos="fade-in" className='exp'>
                                    <i class="las la-users-cog"></i>
                                    <h1>15 +</h1>
                                    <h3>Years of Experience</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About;
