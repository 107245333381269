import './css/footer.css';

import Logo from './images/logo.png';
import { Link as RouterLink } from 'react-router-dom';

import Img1 from './images/footer/1.webp';
import Img2 from './images/footer/2.webp';
import Img3 from './images/footer/3.webp';
import Img4 from './images/footer/4.webp';
import Img5 from './images/footer/5.webp';
import Img6 from './images/footer/6.webp';

import FooterContact from './images/footer/footercontact.webp';


function Footer(){
    return(
        <>
            <footer id="contact-us">
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='social'>
                                <div className='logo'>
                                    <img data-aos-delay="100" data-aos="fade-in" src={Logo} alt="eleven logo" />
                                </div>

                                <span data-aos-delay="200" data-aos="fade-in"></span>

                                <div className='social-links'>
                                    <a data-aos-delay="300" data-aos="fade-in" href="#">
                                        <i class="lab la-facebook-f"></i>
                                    </a>

                                    <a data-aos-delay="400" data-aos="fade-in" href="#">
                                        <i class="lab la-twitter"></i>
                                    </a>

                                    <a data-aos-delay="500" data-aos="fade-in" href="#">
                                        <i class="lab la-linkedin-in"></i>
                                    </a>

                                    <a data-aos-delay="600" data-aos="fade-in" href="#">
                                        <i class="lab la-instagram"></i>
                                    </a>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h1 data-aos-delay="100" data-aos="fade-in"> About the Company </h1>

                                <p data-aos-delay="200" data-aos="fade-in">
                                We use the latest technologies and best strategies to ensure goals are achieved efficiently and effectively. We are here to help you shine and excel in the digital business world.
                                </p>

                                <div data-aos-delay="300" data-aos="fade-in">
                                    <input type='email' required placeholder='Email' />
                                    <a href="mailto:sales@eleven.com.sa">
                                        <i class="las la-share"></i>
                                    </a>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h1 data-aos-delay="100" data-aos="fade-in"> Quick Links </h1>

                                <ul>
                                    <li data-aos-delay="200" data-aos="fade-in">
                                        <a href="#home" aria-label='homepage link'>
                                            Home
                                        </a>
                                    </li>

                                    <li data-aos-delay="300" data-aos="fade-in">
                                        <a href="#about-us" aria-label='about us link'>
                                            About the Company
                                        </a>
                                    </li>

                                    <li data-aos-delay="400" data-aos="fade-in">
                                        <a href="#our-services" aria-label='our services link'>
                                            Our Services 
                                        </a>
                                    </li>

                                    <li data-aos-delay="500" data-aos="fade-in">
                                        <a href="#why-us" aria-label='why us link'>
                                            Why Us? 
                                        </a>
                                    </li>

                                    <li data-aos-delay="600" data-aos="fade-in">
                                        <a href="#contact-us" aria-label='contact us link'>
                                            Contact Us 
                                        </a>
                                    </li>
                                    <li data-aos-delay="700" data-aos="fade-in">
                                        <RouterLink to="/" aria-label='homepage link'>
                                            العربية 
                                        </RouterLink>
                                    </li>
                                </ul>
                            </div>
                        </div>



                        
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h1 data-aos-delay="100" data-aos="fade-in"> Some Photos</h1>

                                <div className='images'>
                                    <img data-aos-delay="200" data-aos="fade-in" src={Img1} alt="footer image" />
                                    <img data-aos-delay="300" data-aos="fade-in" src={Img2} alt="footer image" />
                                    <img data-aos-delay="400" data-aos="fade-in" src={Img3} alt="footer image" />
                                    <img data-aos-delay="500" data-aos="fade-in" src={Img4} alt="footer image" />
                                    <img data-aos-delay="600" data-aos="fade-in" src={Img5} alt="footer image" />
                                    <img data-aos-delay="700" data-aos="fade-in" src={Img6} alt="footer image" />
                                </div>
                            </div>
                        </div>



                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content contact-footer'>
                                <h1 data-aos-delay="100" data-aos="fade-in"> Contact Us </h1>

                                <a data-aos-delay="200" data-aos="fade-in" href="tel:+966509288992" aria-label='call us'>
                                    <i class="las la-phone-volume"></i>
                                    +966 509 288 992
                                </a>

                                <a data-aos-delay="300" data-aos="fade-in" href="mailto:sales@eleven.com.sa">
                                    <i class="las la-envelope"></i>
                                    sales@eleven.com.sa
                                </a>

                                <a data-aos-delay="400" data-aos="fade-in" href="tel:+966509288992" aria-label='address'>
                                    <i class="las la-map-marked-alt"></i>
                                    Address: Jeddah, King Abdulaziz Road - Randa Tower
                                </a>

                                <img data-aos-delay="500" data-aos="fade-in" src={FooterContact} alt="footer contact image" />

                            </div>
                        </div>
                    </div>
                </div>

                <div className='copy-right'>
                    <h5 data-aos-delay="100" data-aos="fade-in"> &copy; Copyright 2024 . All Rights Reserved ELEVEN . </h5>
                </div>
            </footer>
        </>
    )
}

export default Footer;
