import './css/navbar.css';
import Logo from './images/logo.png';

import { useEffect } from "react";
import $ from 'jquery';
import { Link as RouterLink } from 'react-router-dom';
function Navbar(){

    
    useEffect(() => {
        $("#open-nav").on("click", function() {
            $(".nav-eles").slideDown(400);
            $("nav").addClass("active");

            $(".nav-eles").css({display: "flex"});
        });

        $("#close-nav").on("click", function() {
            $(".nav-eles").slideUp(400);
            $("nav").removeClass("active");

        });

        if ($(window).width() < 950) {
            $(".nav-eles ul li").on("click", function() {
                $(".nav-eles").slideUp(400);
                $("nav").removeClass("active");

            });
        }
    
    })


    return(
        <>
            <nav>
                <div className='container-fluid'>
                    <div className='nav-content'>
                        <div className='contact-links'>
                            <a data-aos-delay="100" data-aos="fade-in" href="tel:+966509288992" aria-label='call us'>
                                <i class="las la-phone-volume"></i>
                            </a>
                            <a data-aos-delay="200" data-aos="fade-in" href="mailto:sales@eleven.com.sa">
                                <i class="las la-sms"></i>
                            </a>

                            <span> </span>

                            <div data-aos-delay="300" data-aos="fade-in" className='phonenumber'>
                                <i class="las la-phone-volume"></i>

                                <div>
                                    <h3>Call Us Anytime</h3>
                                    <a href="tel:+966509288992" aria-label='call us'>
                                        +966 509 288 992
                                    </a>

                                </div>
                            </div>
                        </div>



                        
                        <div className='logo'>
                        <button id="open-nav"> <i className="las la-bars"></i> </button>
                        <div className="nav-eles">
                        <button id="close-nav"> <i className="las la-times"></i> </button>
                                <ul>
                                    <li data-aos-delay="400" data-aos="fade-in">
                                        <a href="#home" aria-label='homepage link'>
                                            Home
                                        </a>
                                    </li>

                                    <li data-aos-delay="500" data-aos="fade-in">
                                        <a href="#about-us" aria-label='homepage link'>
                                            About the Company
                                        </a>
                                    </li>

                                    <li data-aos-delay="600" data-aos="fade-in">
                                        <a href="#our-services" aria-label='homepage link'>
                                            Our Services
                                        </a>
                                    </li>

                                    <li data-aos-delay="700" data-aos="fade-in">
                                        <a href="#why-us" aria-label='homepage link'>
                                            Why Us?
                                        </a>
                                    </li>

                                    <li data-aos-delay="800" data-aos="fade-in">
                                        <a href="#contact-us" aria-label='homepage link'>
                                            Contact Us
                                        </a>
                                    </li>

                                    <li data-aos-delay="900" data-aos="fade-in">
                                        <RouterLink to="/" aria-label='homepage link'>
                                            العربية 
                                        </RouterLink>
                                    </li>
                                    

                                </ul>
                            </div>
                            
                            <img data-aos-delay="900" data-aos="fade-in" src={Logo} alt="Eleven logo" />
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;
