import './css/benefits.css';

import BenefitsImg from './images/benefit/benefitsimage.webp';
import Benefit1 from './images/benefit/1.webp';
import Benefit2 from './images/benefit/2.webp';



function Benefits(){
    return(
        <>
            <section className='benefits'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div data-aos-delay="100" data-aos="fade-in" className='benefit-image'>
                                <img src={BenefitsImg} alt='benefitsimg'></img>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='benefit-text'>
                                <h2 data-aos-delay="200" data-aos="fade-in">فوائد التعامل معنا<span> </span> </h2>

                                <h1 data-aos-delay="300" data-aos="fade-in">إليفن  لخدمات الأعمال </h1>

                                <div className='all-benefits'>
                                    <div  data-aos-delay="400" data-aos="fade-in"className='benefit'>
                                        <img src={Benefit1} alt="benefit image " />
                                        <div className='overlay'></div>

                                        <i class="las la-award"></i>

                                        <h3>
                                        اختيارك لـ"إليفن لخدمات الأعمال" يعني أنك
                                        </h3>
                                        <p>
                                        تضع ثقتك في واحدة من الشركات الرائدة في مجال الخدمات الرقمية والتسويق الإلكتروني في المنطقة
                                        </p>
                                    </div>

                                    <div data-aos-delay="500" data-aos="fade-in" className='benefit'>
                                        <img src={Benefit2} alt="benefit image " />
                                        <div className='overlay'></div>

                                        <i class="las la-chart-line"></i>

                                        <h3>
                                        اختيارك لـ"إليفن لخدمات الأعمال"
                                        </h3>
                                        <p>
                                        يعد خطوة استراتيجية نحو تحقيق النجاح والتميز في عالم الأعمال الرقمية
                                        </p>
                                    </div>
                                    
                                </div>

                                <ul>
                                    <li data-aos-delay="600" data-aos="fade-in">
                                        <i class="las la-check"></i>
                                        جودة الخدمة المتميزة
                                    </li>

                                    <li data-aos-delay="700" data-aos="fade-in">
                                        <i class="las la-check"></i>
                                        فريق عمل محترف ومتخصص
                                    </li>

                                    <li data-aos-delay="800" data-aos="fade-in">
                                        <i class="las la-check"></i>
                                        الدعم الكامل والمتابعة المستمرة
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Benefits;