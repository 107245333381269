import './css/whatsapp.css';

function Whatsapp(props){

    return(
        <>
            <a  className={`whatsapp-ico ${props.className}`}  href='https://wa.me/+966509288992'>
                <i class="lab la-whatsapp"></i>
            </a>
        </>
    )
}

export default Whatsapp;