import './css/mainservice.css';
import MainService1 from './images/mainservices/1.webp';
import MainService2 from './images/mainservices/2.webp';
import MainService3 from './images/mainservices/3.webp';

function MainService(){
    return(
        <>
            <section className='main-service'>
                <div className='container'>
                    <div className='row'>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div data-aos-delay="100" data-aos="fade-in" className='service'>
                                <span> 
                                    <i class="las la-print"></i>
                                </span>

                                <img src={MainService1} alt="main service image"/>

                                <div className='text'>
                                    <h1>
                                        Printing Services
                                    </h1>

                                    <p>
                                    <strong>  Diverse Printing:</strong>
                                (Sticker - T-shirt - Sweatshirt - Polo - Lanyard - Mugs - Canvas Bags - etc.)
                                <br></br>
                                <strong > Printing Types: </strong>
                                DTF - Sublimation - Silk Screen - Vinyl - UV DTF





                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div data-aos-delay="200" data-aos="fade-in" className='service'>
                                <span> 
                                    <i class="las la-store-alt"></i>
                                </span>

                                <img src={MainService2} alt="main service image"/>

                                <div className='text'>
                                    <h1>
                                        Digital Marketing
                                    </h1>

                                    <p>
                                        Advertising campaigns across multiple platforms to increase brand awareness
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div data-aos-delay="300" data-aos="fade-in" className='service'>
                                <span> 
                                    <i class="las la-credit-card"></i>
                                </span>

                                <img src={MainService3} alt="main service image"/>

                                <div className='text'>
                                    <h1>
                                        E-commerce Stores
                                    </h1>

                                    <p>
                                        Creating comprehensive e-commerce stores from scratch with product photography
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default MainService;
