import './css/service.css';

import ServiceImg1 from './images/services/1.webp';
import ServiceImg2 from './images/services/2.webp';
import ServiceImg3 from './images/services/3.webp';
import ServiceImg4 from './images/services/4.webp';
import ServiceImg5 from './images/services/5.webp';
import ServiceImg6 from './images/services/6.webp';

function Services(){
    return(
        <>
            <section id="our-services" className='services'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                            <div data-aos-delay="100" data-aos="fade-in" className='service'>
                                <img src={ServiceImg1} alt="service image" />
                                <div className='text'>
                                    <h1>
                                        Marketing and Content Management Services    
                                    </h1>

                                    <span>
                                        <i class="las la-shopping-basket"></i>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                            <div data-aos-delay="200" data-aos="fade-in" className='service'>
                                <img src={ServiceImg2} alt="service image" />
                                <div className='text'>
                                    <h1>
                                        Advertising Services
                                    </h1>

                                    <span>
                                        <i class="las la-ad"></i>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                            <div data-aos-delay="300" data-aos="fade-in" className='service'>
                                <img src={ServiceImg3} alt="service image" />
                                <div className='text'>
                                    <h1>
                                        E-commerce Services
                                    </h1>

                                    <span>
                                        <i class="las la-credit-card"></i>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                            <div data-aos-delay="400" data-aos="fade-in" className='service'>
                                <img src={ServiceImg4} alt="service image" />
                                <div className='text'>
                                    <h1>
                                        Design Services
                                    </h1>

                                    <span>
                                        <i class="lab la-adobe"></i>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                            <div data-aos-delay="500" data-aos="fade-in" className='service'>
                                <img src={ServiceImg5} alt="service image" />
                                <div className='text'>
                                    <h1>
                                        Modeling and Product Photography Services
                                    </h1>

                                    <span>
                                        <i class="las la-camera-retro"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Services;
