import './css/landing.css';

function Landing(){
    return(
        <>
            <section id="home" className='landing'>
                <div className='overlay'></div>
                <div data-aos-delay="1300" data-aos="fade-in" className='triange-1'></div>
                <div data-aos-delay="1400" data-aos="fade-in" className='triangle-2'></div>

                <div className='landing-content'>
                    <h3 data-aos-delay="1000" data-aos="fade-in">Advanced Levels of Expertise and Professionalism</h3>
                    <h1 data-aos-delay="1100" data-aos="fade-in">Eleven Business Services, a Specialized Saudi Agency in E-Business Services</h1>

                    <a  data-aos-delay="1200" data-aos="fade-in" href="https://wa.me/+966509288992">Discover More About Us</a>
                </div>
            </section>
        </>
    )
}

export default Landing;
