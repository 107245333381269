import './css/rates.css';
import User from './images/default.webp';



import React, { useState,useEffect } from 'react';


import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,Navigation,Pagination} from 'swiper';


import Rate1 from './images/rates/1.webp';
import Rate2 from './images/rates/2.webp';
import Rate3 from './images/rates/3.webp';
import Rate4 from './images/rates/4.webp';


function Rates(){
    return(
        <>
            <section className='rates'>
                <div className='container'>
                    <div className='rates-content'>
                        <div className='overlay-1'></div>
                        <div className='overlay-2'></div>
                        
                        <span>
                            <i class="las la-quote-right"></i>
                        </span>

                        <div className='buttons'>
                            <button className='prev-btn' aria-label='prev rate slide button'>
                            <i class="las la-arrow-left"></i>
                            </button>
                            <button className='next-btn' aria-label='next rate slide button'>
                            <i class="las la-arrow-right"></i>
                            </button>

                        </div>

                        <div className='rates-slides'>
                        <Swiper grabCursor={true} spaceBetween={50} slidesPerView={1} navigation={{
                            nextEl: '.next-btn',
                            prevEl: '.prev-btn',
                            }} pagination={false}
                                autoplay={{
                                    delay: 6000,
                                    disableOnInteraction: false,
                                }}
                            modules={[Autoplay,Navigation,Pagination]}
                            >
                                <SwiperSlide>
                                    <div className='rate'>
                                        <img src={Rate1} alt="rate img" />
                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className='rate'>
                                    <img src={Rate2} alt="rate img" />

                                    </div>
                                </SwiperSlide>



                                <SwiperSlide>
                                    <div className='rate'>
                                    <img src={Rate3} alt="rate img" />

                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className='rate'>
                                    <img src={Rate4} alt="rate img" />

                                    </div>
                                </SwiperSlide>



                            </Swiper>
                            
                        </div>

                        <div className='users'>
                            <img src={Rate1} alt="user rate image" />
                            <img src={Rate2} alt="user rate image" />
                            <img src={Rate3} alt="user rate image" />
                            <img src={Rate4} alt="user rate image" />

                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Rates;