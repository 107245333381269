import './css/about.css';

import AboutImg1 from './images/about/lg_0.webp';
import AboutImg2 from './images/about/sm_1.webp';

import BenImg1 from  './images/about/ben1_0.webp';
import BenImg2 from  './images/about/ben2_1.webp';




function About(){
    return(
        <>
            <section id="about-us" className='about'>
                <div className='container'>
                    <div className='row'>

                        <div className='col-lg6 col-md-6 col-sm-12 col-12'>
                            <div className='about-text'>
                                <h2 data-aos-delay="100" data-aos="fade-in">  عن الشركة<span> </span> </h2>

                                <h1 data-aos-delay="200" data-aos="fade-in">
                                    ريادتنا في عالم الأعمال الإلكترونية والتسويق
                                </h1>

                                <p data-aos-delay="300" data-aos="fade-in">
                                إليفن لخدمات الأعمال"، وكالة سعودية مبتكرة تتخصص في تقديم مجموعة شاملة من الخدمات الإلكترونية والتسويقية، بما في ذلك تصميم وتنفيذ حلول التسويق الإلكتروني، إدارة المحتوى، الدعاية والإعلان، تصميم الهويات البصرية و تصوير المنتجات،   بالإضافة إلى تطوير وإدارة المتاجر الإلكترونية. نسعى في "إليفن" لتوفير خدمات متميزة تضمن لعملائنا التألق والنجاح في عالم الأعمال الرقمي.


                                </p>

                                <div className='benefits'>
                                    <div data-aos-delay="400" data-aos="fade-in">
                                        <span>
                                            <i class="las la-award"></i>
                                        </span>
                                        <h3>الاحترافية والجودة</h3>
                                        <p>
                                        نقدم لعملائنا خدمات متقنة ومبتكرة تعكس خبرتنا الواسعة واحترافيتنا في كل مشروع نتولاه.
                                        </p>

                                        <img src={BenImg1} alt="benefit image" />
                                    </div>

                                    <div data-aos-delay="500" data-aos="fade-in">
                                        <span>
                                            <i class="las la-puzzle-piece"></i>
                                        </span>
                                        <h3>الشمولية والتكامل</h3>
                                        <p> من خلال تقديم مجموعة واسعة من الخدمات تحت سقف واحد، نوفر لعملائنا حلولاً متكاملة تلبي جميع احتياجاتهم الرقمية والتسويقية، مما يساعد في تحقيق أهدافهم بكفاءة وفعالية.</p>

                                        <img src={BenImg2} alt="benefit image" />
                                    </div>

                                </div>

                                <ul>
                                    <li data-aos-delay="100" data-aos="fade-in">
                                        <i class="las la-check"></i>
                                        التزام بالجودة
                                    </li>

                                    <li data-aos-delay="200" data-aos="fade-in">
                                        <i class="las la-check"></i>
                                        التفاني في التنفيذ
                                    </li>

                                    <li data-aos-delay="300" data-aos="fade-in">
                                        <i class="las la-check"></i>
                                        تلبية احتياجات السوق
                                    </li>

                                    
                                </ul>
                            </div>
                        </div>


                        <div className='col-lg6 col-md-6 col-sm-12 col-12'>
                            <div className='about-image'>
                                <div className='spans'>
                                    <span data-aos-delay="100" data-aos="fade-in" className='long'></span>
                                    <span data-aos-delay="200" data-aos="fade-in" className='short'></span>
                                    <span data-aos-delay="300" data-aos="fade-in" className='short'></span>
                                </div>

                                <img data-aos-delay="100" data-aos="fade-in" className='lg-img' src={AboutImg1} alt="eleven about image"/>

                                <img data-aos-delay="200" data-aos="fade-in" className='sm-img' src={AboutImg2} alt="eleven about image"/>

                                <div data-aos-delay="300" data-aos="fade-in" className='exp'>
                                    <i class="las la-users-cog"></i>
                                    <h1>15 +</h1>
                                    <h3> سنوات من الخبرة</h3>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </section>
        </>
    )
}

export default About;