import './css/benefits.css';

import BenefitsImg from './images/benefit/benefitsimage.webp';
import Benefit1 from './images/benefit/1.webp';
import Benefit2 from './images/benefit/2.webp';

function Benefits(){
    return(
        <>
            <section className='benefits'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div data-aos-delay="100" data-aos="fade-in" className='benefit-image'>
                                <img src={BenefitsImg} alt='benefitsimg'></img>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='benefit-text'>
                                <h2 data-aos-delay="200" data-aos="fade-in">The Benefits of Dealing With Us</h2>

                                <h1 data-aos-delay="300" data-aos="fade-in">Elevon Business Services</h1>

                                <div className='all-benefits'>
                                    <div  data-aos-delay="400" data-aos="fade-in" className='benefit'>
                                        <img src={Benefit1} alt="benefit image" />
                                        <div className='overlay'></div>

                                        <i className="las la-award"></i>

                                        <h3>
                                        Choosing "Elevon Business Services" means you
                                        </h3>
                                        <p>
                                        are placing your trust in one of the leading companies in the field of digital services and online marketing in the region.
                                        </p>
                                    </div>

                                    <div data-aos-delay="500" data-aos="fade-in" className='benefit'>
                                        <img src={Benefit2} alt="benefit image" />
                                        <div className='overlay'></div>

                                        <i className="las la-chart-line"></i>

                                        <h3>
                                        Choosing "Elevon Business Services"
                                        </h3>
                                        <p>
                                        is a strategic step towards achieving success and excellence in the digital business world.
                                        </p>
                                    </div>
                                    
                                </div>

                                <ul>
                                    <li data-aos-delay="600" data-aos="fade-in">
                                        <i className="las la-check"></i>
                                        Exceptional service quality
                                    </li>

                                    <li data-aos-delay="700" data-aos="fade-in">
                                        <i className="las la-check"></i>
                                        Professional and specialized team
                                    </li>

                                    <li data-aos-delay="800" data-aos="fade-in">
                                        <i className="las la-check"></i>
                                        Full support and continuous follow-up
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Benefits;
