import './css/latestwork.css';

import LatestWorkImg1 from './images/latestwork/1.webp';
import LatestWorkImg2 from './images/latestwork/2.webp';
import LatestWorkImg3 from './images/latestwork/3.webp';



function LatestWork(){
    return(
        <>
            <section className='latest-work'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='latest-work-header'>
                                <h2 data-aos-delay="100" data-aos="fade-in"> أخر أعمالنا   <span> </span></h2>
                                <h1 data-aos-delay="200" data-aos="fade-in">  بعض أعمال شركة إليفين الإحترافية </h1>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className='latest-work-image'>
                                <img data-aos-delay="300" data-aos="fade-in" src={LatestWorkImg1} alt="latest work image"/>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='latest-work-image'>
                                <img data-aos-delay="400" data-aos="fade-in" src={LatestWorkImg2} alt="latest work image"/>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className='latest-work-image'>
                                <img data-aos-delay="500" data-aos="fade-in" src={LatestWorkImg3} alt="latest work image"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LatestWork;