import './css/landing.css';

function Landing(){
    return(
        <>
            <section id="home" className='landing'>
                <div className='overlay'></div>
                <div data-aos-delay="1300" data-aos="fade-in" className='triange-1'></div>
                <div data-aos-delay="1400" data-aos="fade-in" className='triangle-2'></div>

                <div className='landing-content'>
                    <h3 data-aos-delay="1000" data-aos="fade-in"> مستويات متقدمة من الخبرة والاحترافية  </h3>
                    <h1 data-aos-delay="1100" data-aos="fade-in"> إليفن لخدمات الأعمال وكالة سعودية متخصصة في خدمات الأعمال الإلكترونية </h1>

                    <a  data-aos-delay="1200" data-aos="fade-in"href="https://wa.me/+966509288992"> إكتشف المزيد عنا </a>
                </div>
            </section>
        </>
    )
}

export default Landing;